input {
  border: none;
  border-bottom: solid 1px #f0f0f0;
  height: 24 + 8 * 2px;
  &:focus {
    outline: none;
  }
  padding:0;
  width:calc(100% - 30px);
  padding-left:30px;
  position: relative;
}
.inp-next {
  margin-top: 30px;
}

.inp-tel {
  &:nth-of-type(1) {
    background:#FFFFFF url("../imgs/mobile@2x.png") 4px no-repeat;//input前面的图标
    background-size: 16px;//图标大小
  }
}
.inp-vcode {
  &:nth-of-type(1) {
    background:#FFFFFF url("../imgs/mail@2x.png") 4px no-repeat;
    background-size: 16px;
  }
}
.inp-account {
  &:nth-of-type(1) {
    background:#FFFFFF url("../imgs/user@2x.png") 4px no-repeat;
    background-size: 16px;
  }
}
.inp-psw {
  &:nth-of-type(1) {
    background:#FFFFFF url("../imgs/lock@2x.png") 4px no-repeat;
    background-size: 16px;
  }
}

.page-login {
  height: 100%;
}
.dlg-login {
  width: 400px;
  height: 500px;
  background-color: #fff;
}



.box-bd {
  width: 320px;
  margin: auto;
}
.box-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 46px;
}

.btn-login {
  border: none;
  width: 320px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #fff;
  background: #2f54eb;
  border-radius: 2px;
}
.btn-close {
  width:18px;
  height: 18px;
  background-size: 100%;
  background-image: url("../imgs/close@2x.png");
  position: absolute;
  right:16px;
  top:16px;
}



.tips {
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #f5222d;
  line-height: 24px;
  text-align: left;
  margin: auto;
  margin-top: 8px;
}