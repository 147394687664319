


























































@import "../css/common";

  .box-vcode {
    position: relative;
    width: 100%;
  }
  .btn-vcode {
    width: 102px;
    height: 24px;
    line-height: 21px;
    background: #2f54eb;
    border-radius: 2px;
    border: 1px solid #2f54eb;
    color: #ffffff;
    font-size: 14px;

    position: absolute;
    margin: auto;
    // top:0;
    bottom: 14px;
    right: 0;
    &-running {
      opacity: 0.2;
    }
  }
