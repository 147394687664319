



























































































@import "css/common";

.box-bd {
  width: 320px;
  margin: auto;
}
.inp-next {
  margin-top:16px;
}
