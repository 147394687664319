














































@import "css/common";

.box-bd {
  width: 320px;
  margin: auto;
}
.inp-next {
  margin-top:24px;
}
.btn-jump {
  margin-top:13px;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
