

































@import "../css/common";
.dlg-succ {
  background: rgba(0, 0, 0, 0.6);
}
.bd {
  display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    img {
      margin-bottom:24px;
    }

}
.btn-2login {
  margin-top:88px;
}
