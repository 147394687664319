























































































































































































@import "css/common";


  .box-psw {
    position: relative;
    display: inline-block;
    width: 100%;
    .btn-seen {
      position: absolute;
      right: 0;
      bottom: 14px;
      margin: auto;

      width: 16px;
      height: 16px;
      background-size: contain;
      background-image: url("imgs/eye_open@2x.png");
      &-open {
        background-image: url("imgs/eye_close@2x.png");
      }
    }
  }

  .misspsw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
  }


.btn-regi {
  color: #2f54eb;
  font-size: 14px;
  margin-top: 12px;
}
