.box-hd {
  height: 103px;
  margin-bottom: 40px;
  background: rgba(0, 0, 0, 0.04);
}
.hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0px;
}
.hd .btn-txt {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.tabs .btn-tab {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.45);
  position: relative;
  height: 39px;
  margin-bottom: 11px;
}
.tabs .btn-tab-active {
  color: #2f54eb;
}
.tabs .btn-tab-active::after {
  position: absolute;
  width: 24px;
  height: 4px;
  background-color: #2f54eb;
  border-radius: 2px;
  content: "";
  margin: auto;
  left: 0;
  right: 0;
  bottom: 3px;
}
.btn-back {
  position: relative;
  padding-left: 24PX;
  height: 20px;
  line-height: 20px;
}
.btn-back::after {
  content: "";
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-size: 100%;
  background-image: url("../imgs/left@2x.png");
}
